import * as React from 'react';
import { useRef } from 'react';

import { PostEntity } from '../../types';
import { Categories } from '../Categories';
import { Iframe } from '../Iframe/lazy';

const LastPost = ({ categories, title, source }: PostEntity): JSX.Element => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    return (
        <div className={'flex items-center mx-auto w-full'}>
            <div className={'flex flex-col justify-center mt-5 lg:mt-0 lg:pr-10 w-5/12'}>
                <div>
                    <Categories categories={categories} />
                    <h2 className={'m-0 lg:mb-5 text-blue-dark font-medium text-4xl'}>{title}</h2>
                    <button
                        className={'pill-button'}
                        onClick={() => {
                            iframeRef.current?.contentDocument.querySelector('a').click();
                        }}>
                        Assistir
                    </button>
                </div>
            </div>
            <div className={'w-7/12'}>
                <Iframe
                    title={title}
                    showShadow
                    priority
                    ref={iframeRef}
                    src={source}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        </div>
    );
};

export default LastPost;
