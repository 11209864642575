import Link from 'next/link';
import * as React from 'react';

import categoriesName from '../../categories.json';

export const Categories: React.FC<{ categories: string[]; className?: string }> = ({
    categories = [],
    className
}) => (
    <h4
        className={`leading-tight uppercase font-bold tracking-wider my-5 border-current text-green-main hover:text-green-light inline-hover ${className}`}>
        {categories
            .map((category) => categoriesName[category])
            .filter(Boolean)
            .map((category, index, array) => (
                <React.Fragment key={category?.slug}>
                    <Link href={`/categoria/${category?.slug}`}>
                        <a className={'text-green-main hover:text-green-light inline-hover'}>
                            {category?.label}
                        </a>
                    </Link>
                    {array.length - 1 !== index && (
                        <span className={'text-green-main'}>{', '}</span>
                    )}
                </React.Fragment>
            ))}
    </h4>
);
